import { Fade } from "react-awesome-reveal";

const Threads = () => {
  return (
    <section className="w-full flex justify-start items-center flex-col mt-16 lg:mt-[70px]">
      <div className="w-[90%] max-w-[1300px] flex justify-start items-center sm:items-start flex-col">
        <Fade triggerOnce direction="down">
          <div className="sm:text-left text-center ">
            <h2 className="text-black  font-semibold text-2xl sm:leading-[1.4] sm:text-[30px] xl:text-[35px]">
              Perfume Oils
            </h2>
            <p className="text-sm md:text-base font-normal text-slate-800">
              Our products are 100% alcohol-free pure perfume oils derived from ingredients that are sourced from around the world. Whether it's oud from Cambodia, bergamot from Italy, or rose from Turkey, we search for the best and highest quality materials to manufacture our fragrance oils. Pairrit products are vegan and cruelty free. We manufacture our perfume oils in France and all of our products are IFRA compliant. 

            </p>
          </div>
        </Fade>
        <div className="grid grid-cols-2  w-full gap-7 mt-8">
          <Fade triggerOnce cascade damping={0.1}>
            <ThreadBox
              step={1}
              title="Inspired Scents"
              desc="Pairrit competes with the designer brands. It does not use their fragrances and is not associated in any way with the designer brands or their manufacturers. All trademarks are property of their respective owners."
              img="/assets/imgs/home/states/MI.png"
              stepColor="bg-[#206CD6]"
              gif="/assets/imgs/about/thread1.gif"
            />
            <ThreadBox
              gif="/assets/imgs/about/thread2.gif"
              step={2}
              stepColor="bg-[#85C4FF]"
              title="Original Scents"
             desc="Pairrit competes with the designer brands. It does not use their fragrances and is not associated in any way with the designer brands or their manufacturers. All trademarks are property of their respective owners."
              img="/assets/imgs/home/states/LA.png"
              order
            />
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default Threads;

const ThreadBox = ({ img, title, gif, step, desc, order, stepColor }) => {
  return (
    <div
      className={`${
        false ? "flex-col-reverse" : "flex-col"
      } h-full  flex justify-between items-start sm:gap-5 category-card rounded-3xl p-2 sm:p-4 shadow`}
    >
      <div
        className={`flex justify-start w-full items-start ${
          false? "flex-col" : "flex-col-reverse"
        } gap-4`}
      >
         <p className="text-black text-sm font-semibold">{desc}</p>
       
        <h3 className="text-black  sm:text-[18px] xl:text-[25px] font-semibold text-center">
          {title}
        </h3>
       
       
      </div>
      <img
        src={img}
        className="lg:max-w-none max-w-[400px] self-center w-full object-contain"
        alt=""
      />
    </div>
  );
};
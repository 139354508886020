import { Fade } from "react-awesome-reveal";
import Card from "../../common/Card";

const CompHeader = (props) => {
  const { fee } = props;
  return (
    <Card className={fee ? "bg-white" : "bg-white"}>
      <div className="containBig bg-primary-gray-100  px-5 rounded justify-start items-center flex-col">
        <div className="grid  grid-cols-1 lg:grid-cols-3 gap-x-3 gap-y-4 ">
          <Fade direction="up" triggerOnce cascade>
           <PayoutBox
              img="/assets/imgs/about/secure.png"
              title="7 Day Returns"
              desc="Any Issues, We Got You Covered!"
            />
            <PayoutBox
              img="/assets/imgs/about/selling.png"
              title={"Huge Savings"}
              desc={"Discover and expand your fragrance collection for a Fraction."}
            />
            <PayoutBox
              img="/assets/imgs/about/safe.png"
              title={"Safe Shopping"}
              desc={"All of our products are guaranteed to be 100% Safe."}
            />
          </Fade>
        </div>
      </div>
    </Card>
  );
};

export default CompHeader;

const PayoutBox = ({ img, title, desc }) => {
  return (
    <div className="flex mx-auto max-w-[360px] items-center flex-col text-center gap-2">
      <img
        src={img}
        className="object-contain max-h-[30px] xl:max-h-[55px]"
        alt=""
      />
      <h3 className="text-[#3e454b] mt-2 text-[16px]  font-medium">
        {title}
      </h3>
      <p className="text-gray-500 text-sm font-normal">{desc}</p>
    </div>
  );
};

import React from 'react'

const WhyUs = () => {
    return (
      <React.Fragment>
        <div className="mt-10 mb-4 text-center font-semibold text-2xl">
          Why Pairrit?
        </div>

        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
          <div className="bg-white border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Fragrance Marketplace</div>
            <div className="font-medium mt-1 text-xs">
              All Decants Are From Original Bottles
            </div>
          </div>
          <div className="bg-white border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Perfume Oils</div>
            <div className="font-medium mt-1 text-xs">
              Long Lasting Alcohol Free Oils
            </div>
          </div>
          <div className="bg-white border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Original Oils</div>
            <div className="font-medium mt-1 text-xs">
              We Pair your Favorite Scents
            </div>
          </div>
          <div className="bg-white border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Inspired Oils</div>
            <div className="font-medium mt-1 text-xs">
              100% Genuine Perfume Oils
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default WhyUs
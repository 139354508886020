import { Disclosure } from '@headlessui/react'
import { Icon } from '@iconify/react'
import React from 'react'

const NotifyButton = ({
  placeholder,
  className,
}: {
  placeholder?: string;
  className?: string;
}) => {
  return (
    <div className={className}>
      <Disclosure>
        <Disclosure.Button className="w-full flex gap-2 rounded-lg  lg:px-4 lg:py-2 text-left text-xs font-medium text-orange-900 hover:bg-orange-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/75">
          <Icon
            className={`h-5 w-5 text-purple-500 text-lg `}
            icon="clarity:heart-solid"
          />
          <span className='text-red-500 text-2xs'>{placeholder}</span>
        </Disclosure.Button>
      </Disclosure>
    </div>
  );
};

export default NotifyButton
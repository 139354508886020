import * as Tabs from '@radix-ui/react-tabs';
import Card from '../../../common/Card';

export default function AboutTabs({ info, isListing }: { info?: any; isListing?: boolean }) {
  return (
    <div className="py-5">
      <div className="min-w-[11px] min-h-full bg-accentYellow-500 " />
      <Card className=" py-4 bg-white shadow-lg rounded-xl">
        {!isListing ? (
          <div className="text-xs font-medium text-neutral-700 [&>b]:pl-2 [&>b]:pr-1">
            <b>Status:</b>
            {info.status}
            <b>Brand: </b>
            {info.brandName} <b>Model:</b>
            {info.model} <b>Category:</b>
            {info.postCategory} <b>Method:</b>
            {info.receiveMethod}
            <b>Duration:</b>
            {info.duration}
          </div>
        ) : (
          <div className="text-sm font-medium text-neutral-700 [&>b]:pl-3 [&>b]:pr-1">
            <b>Size:</b>
            {info.size < 0.1 ? "SMPL" : `${info.size}ml`}
            <b>Condition:</b>
            {info.condition}
            <b>Brand:</b>
            {info.brand} <b>Model:</b>
            {info.model}
            <b>Formula:</b>
            {info.formula}
          </div>
        )}
      </Card>
      <Card className="px-3 shadow-lg pt-2 pb-4 bg-white rounded-xl ">
        <h5 className="font-bold text-sm mb-1 underline">About Description</h5>
        <p className="text-xs font-medium"> {info.description}</p>
      </Card>
    </div>
  );
}

const tabs = ['About this listing', 'Product specs'];

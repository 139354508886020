import React from "react";
import { Link } from "react-router-dom";
import Card from "../../../common/Card/Card";
import GradientBtn from "../../../buttons/GradientBtn";

const Flags = ({ m1, m2, m3, m4, m5 }: any) => {
  return (
    <Card
      noMt
      className="lg:hidden bg-gradient-to-r from-[#faf0e6] to-[#f5f2e8] md:p-2 rounded-xl shadow-lg  p-0 h-[12em] overflow-hidden"
    >
      <div className="backdrop-blur-xs z-30 items-center flex flex-col md:mt-0 -pt-4">
        <p className="md:text-4xl text-3xl pb-1 font-bold bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-red-700 ">
          {m1}
        </p>
        <div className="text-sm font-semibold md:font-bold text-slate-800">
          {m2}
        </div>

        <Link className="my-2" to={`/market${m5}`}>
          <button className="w-full px-10 rounded-full bg-gradient-to-l from-red-600 gap-1.5 to-orange-400 text-white  h-10 flex items-center justify-center hover:opacity-75 shadow  border-2 border-white shadow ">
            <p className="md:text-xs font-bold leading-tight text-white capitalize text-xs w-full">
              {m3}
            </p>
          </button>
        </Link>
      </div>

      <img
        className=" w-[450px] md:w-full object-cover bg-transparent -mt-[4em] md:-mt-[4.5em] h-full "
        src={`/assets/imgs/home/shopit${m4}.png`}
        alt=""
      />
    </Card>
  );
};

export default Flags;

import { Icon } from "@iconify/react";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import GradientBtn from "../../buttons/GradientBtn";
import { cn } from "../../../helpers/utils";
import SortbySelect from "../../common/Selectors/sortbyselect";
import { SearchFilterMob } from "../Search/filter-mob";
import { SearchFilterMobFull } from "../Search/filter-mob-full";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  fetchProducts,
  fetchMarket,
  resetFilter,
  fetchOils,
} from "../../../redux/features/market/marketplaceSlice";
import Loader from "../../common/Loader/loader";
import ShopFlags from "../Home/Flags/ShopFlags";
import Flags from "../Home/Flags";
import NotAdded from "../../common/Error/NotAdded";
import Card from "../../common/Card/Card";
import Skeleton from "@mui/material/Skeleton";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import React from "react";
import FlagsBanner from "../Home/Flags/FlagsBanner";
import { useMediaQuery } from "../../../hooks/useMediaQueries";
import mixpanel from "mixpanel-browser";
import GearList from "../Single-Page/New-Gear/gear-list";
import Flags2 from "../Home/Flags/Flags2";


const OilShop = () => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get("sort");
  const { oilList, oilLoading, oilError } = useAppSelector(
    (state) => state.marketplace
  );

  useEffect(() => {
    mixpanel.track("Oil screeen view");

    if (
     sort
    ) {
      dispatch(
        fetchOils({
          sort
        })
      );
    } 
  }, [searchParams]);
  return (
    <div className="mb-[8em]">
      <Flags
        m1="Perfume Oils"
        m2="Perfume Oils Are All Inspired"
        m3="Shop Oils Here"
        m4={3}
        m5=""
      />
      <Flags2
        m1="Perfume Oils"
        m2="Perfume Oils Are All Inspired"
        m3="Shop Oils Here"
        m4={3}
        m5=""
      />
      <div className="h-0 md:pb-4 pb-2 mt-1 border-t border-slate-400/20" />

      {/* <AnimatePresence>
        {isSearchFilterVisible && (
          <SearchFilterMob setFilterVisible={setSearchFilterVisible} />
        )}
      </AnimatePresence> */}

      <div className="flex grid grid-cols-12 gap-5 justify-between">
        <div className="lg:col-start-2 lg:col-span-10 col-span-full">
          <div className="flex justify-between ">
            <SortbySelect
              options={options}
              wrapperClass={cn("")}
              className="lg:w-full w-fit bg-zinc-100"
              onClick={() => {
                mixpanel.track("Best match button clicked");
              }}
            />
          </div>
          {oilLoading ? (
            <Card className="bg-white mt-4">
              <Skeleton
                className="my-2 mx-auto"
                variant="rounded"
                width={350}
                height={40}
              />
            </Card>
          ) : oilList ? (
            <GearList
              value={oilList}
              className="md:grid-cols-4 lg:gap-5 lg:grid-cols-5 xl:grid-cols-5 "
            />
          ) : (
            <NotAdded
              m1="Nice Try"
              m2="No Products"
              m3="No matches to your request."
            />
          )}
        </div>
      </div>

      {/* <Pagination
        className="mt-3 relative lg:mt-10 bottom-10 bg-white p-1 rounded-xl shadow-lg items-center  justify-center"
        count={8}
        variant="outlined"
        color="primary"
        boundaryCount={2}
        page={page + 1}
        onChange={handleChange}
      /> */}
    </div>
  );
};

export default OilShop;

const options = [
  { label: "Most Popular", value: "POPULAR" },
  { label: "Recent Released", value: "RECENT" },
  { label: "Price Low to High", value: "LOW" },
  { label: "Price High to Low", value: "HIGH" },
  { label: "Top Rated", value: "RATE" },
];


export const listO0 = [
  {
    id: "FRG100455",
    brandName: "PAIRRIT",
    brandAbr: "Layon + BDC",
    model: "Connecticut",
    img: "/assets/imgs/home/states/CT.png",
    formula: "OIL",
    year: 2015,
    category: "ORIENTAL",
    popularRate: 9.75,
    status: "BESTSELLER",
    gender: "UNISEX",
    rate: 4.85,
    startPrice: 18.99,
    votes: 5906,
    createdDateTime: "2024-09-25T00:50:37.41",
  },
  {
    id: "FRG100456",
    brandName: "PAIRRIT",
    brandAbr: "Tendre + Libre",
    model: "Florida",
    img: "/assets/imgs/home/states/FL.png",
    formula: "OIL",
    year: 2025,
    category: "WOODY",
    popularRate: 9.45,
    status: "TREND",
    gender: "UNISEX",
    rate: 4.75,
    startPrice: 14.99,
    votes: 2792,
    createdDateTime: "2024-10-22T11:13:02.514",
  },
  {
    id: "FRG100457",
    brandName: "PAIRRIT",
    brandAbr: "Sauvage + Allure",
    model: "Arizona",
    img: "/assets/imgs/home/states/AZ.png",
    formula: "OIL",
    year: 2025,
    category: "FLORAL",
    popularRate: 9.3,
    status: "GOLD",
    gender: "UNISEX",
    rate: 4.94,
    startPrice: 15.99,
    votes: 4505,
    createdDateTime: "2024-10-23T14:05:20.999",
  },
  {
    id: "FRG100458",
    brandName: "PAIRRIT",
    brandAbr: "Baccarat + Aventus",
    model: "Georgia",
    img: "/assets/imgs/home/states/GA.png",
    formula: "OIL",
    year: 2025,
    category: "FLORAL",
    popularRate: 9.85,
    status: "TREND",
    gender: "UNISEX",
    rate: 4.65,
    startPrice: 13.99,
    votes: 5405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100459",
    brandName: "PAIRRIT",
    brandAbr: "Baccarat Extrait + J'Adore",
    model: "California",
    img: "/assets/imgs/home/states/CA.png",
    formula: "OIL",
    year: 2025,
    category: "ORIENTAL",
    popularRate: 9.05,
    status: "GOLD",
    gender: "UNISEX",
    rate: 4.89,
    startPrice: 15.99,
    votes: 9405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100460",
    brandName: "PAIRRIT",
    brandAbr: "Hypnotic + BLK Opium",
    model: "New Jersey",
    img: "/assets/imgs/home/states/NJ.png",
    gender: "UNISEX",
    startPrice: 16.99,
    rate: 4.62,
    status: "GOLD",
    year: 2025,
    category: "ORIENTAL",
    formula: "OIL",
    popularRate: 9.1,
  },
  {
    id: "FRG100461",
    brandName: "PAIRRIT",
    brandAbr: "NY Nights + Tobacco Vanille",
    model: "Colorado",
    img: "/assets/imgs/home/states/CO.png",
    gender: "UNISEX",
    startPrice: 18.99,
    rate: 4.81,
    status: "GOLD",
    year: 2007,
    category: "ORIENTAL",
    formula: "OIL",
    popularRate: 9.5,
  },
  {
    id: "FRG100462",
    brandName: "PAIRRIT",
    brandAbr: "Spicebomb Ex + Portrait",
    model: "Alabama",
    img: "/assets/imgs/home/states/AL.png",
    gender: "UNISEX",
    startPrice: 13.99,
    rate: 4.71,
    status: "TREND",
    year: 2005,
    category: "ORIENTAL",
    formula: "OIL",
    popularRate: 9.7,
  },
  {
    id: "FRG100463",
    brandName: "PAIRRIT",
    brandAbr: "Grand Soir + Homme Intense",
    model: "Michigan",
    img: "/assets/imgs/home/states/MI.png",
    gender: "UNISEX",
    startPrice: 15.99,
    rate: 4.82,
    status: "NEW",
    year: 2006,
    category: "ORIENTAL",
    formula: "OIL",
    votes: 2405,
    popularRate: 9.65,
  },
  {
    id: "FRG100464",
    brandName: "PAIRRIT",
    brandAbr: "Greenwich + Born in Roma",
    model: "Louisiana",
    img: "/assets/imgs/home/states/LA.png",
    formula: "OIL",
    year: 2012,
    category: "WOODY",
    popularRate: 9.15,
    status: "TREND",
    gender: "UNISEX",
    rate: 4.95,
    startPrice: 15.99,
    votes: 2405,
  },
  {
    id: "FRG100465",
    brandName: "PAIRRIT",
    brandAbr: "Irish Tweed + Le Male PAR",
    model: "Texas",
    img: "/assets/imgs/home/states/TX.png",
    formula: "OIL",
    year: 2020,
    category: "FRESH",
    popularRate: 9.05,
    status: "BESTSELLER",
    gender: "UNISEX",
    rate: 4.89,
    startPrice: 14.99,
    votes: 4405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100466",
    brandName: "PAIRRIT",
    brandAbr: "Orchid + Good Girl",
    model: "Virgina",
    img: "/assets/imgs/home/states/VA.png",
    formula: "OIL",
    year: 2011,
    category: "FLORAL",
    popularRate: 8.85,
    status: "TREND",
    gender: "UNISEX",
    rate: 4.79,
    startPrice: 13.99,
    votes: 1405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100467",
    brandName: "PAIRRIT",
    brandAbr: "Rosa Luna + La Nuit",
    model: "North Carolina",
    img: "/assets/imgs/home/states/NC.png",
    gender: "UNISEX",
    startPrice: 18.99,
    rate: 4.81,
    status: "GOLD",
    year: 2007,
    category: "ORIENTAL",
    formula: "OIL",
    popularRate: 9.5,
  },
  {
    id: "FRG100468",
    brandName: "PAIRRIT",
    brandAbr: "Y + Althaïr",
    model: "Pennsylvania",
    img: "/assets/imgs/home/states/PA.png",
    gender: "UNISEX",
    startPrice: 13.99,
    rate: 4.71,
    status: "TREND",
    year: 2005,
    category: "ORIENTAL",
    formula: "OIL",
    popularRate: 9.7,
  },
  {
    id: "FRG100469",
    brandName: "PAIRRIT",
    brandAbr: "Paradoxe + Baccarat Ex",
    model: "South Carolina",
    img: "/assets/imgs/home/states/SC.png",
    gender: "UNISEX",
    startPrice: 13.99,
    rate: 4.82,
    status: "NEW",
    year: 2006,
    category: "ORIENTAL",
    formula: "OIL",
    popularRate: 9.65,
  },
  {
    id: "FRG100470",
    brandName: "PAIRRIT",
    brandAbr: "Fireplace + Delina",
    model: "New York",
    img: "/assets/imgs/home/states/NY.png",
    formula: "OIL",
    year: 2012,
    category: "WOODY",
    popularRate: 9.15,
    status: "TREND",
    gender: "UNISEX",
    rate: 4.95,
    startPrice: 15.99,
    votes: 9405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100471",
    brandName: "PAIRRIT",
    brandAbr: "Ombre Leather + Satin Mood",
    model: "Nevada",
    img: "/assets/imgs/home/states/NV.png",
    formula: "OIL",
    year: 2020,
    category: "FRESH",
    popularRate: 9.05,
    status: "BESTSELLER",
    gender: "UNISEX",
    rate: 4.89,
    startPrice: 10.99,
    votes: 9405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100472",
    brandName: "PAIRRIT",
    brandAbr: "Lady Million + Poison",
    model: "Hawaii",
    img: "/assets/imgs/home/states/HI.png",
    formula: "OIL",
    year: 2011,
    category: "FLORAL",
    popularRate: 8.85,
    status: "TREND",
    gender: "UNISEX",
    rate: 4.79,
    startPrice: 10.99,
    votes: 11405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100473",
    brandName: "PAIRRIT",
    brandAbr: "Libre Intense + Baccarat",
    model: "Kentucky",
    img: "/assets/imgs/home/states/KY.png",
    formula: "OIL",
    year: 2020,
    category: "FRESH",
    popularRate: 9.05,
    status: "BESTSELLER",
    gender: "UNISEX",
    rate: 4.89,
    startPrice: 10.99,
    votes: 9405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100474",
    brandName: "PAIRRIT",
    brandAbr: "Portrait + Chloe",
    model: "Massachusetts",
    img: "/assets/imgs/home/states/MA.png",
    formula: "OIL",
    year: 2011,
    category: "FLORAL",
    popularRate: 8.85,
    status: "TREND",
    gender: "UNISEX",
    rate: 4.79,
    startPrice: 10.99,
    votes: 11405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
];
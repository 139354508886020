import { Icon } from "@iconify/react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { useState } from "react";
import { cn } from "../../../helpers/utils";
import GradientBtn from "../../buttons/GradientBtn";
import { Input } from "./input";

type PaymentExtProps = {};
export function PaymentExt({ ...props }: PaymentExtProps) {
  const [state, setState] = useState("credit");
  return (
    <>
      <div className="grid grid-cols-3 md:lg:xl:grid-cols-3 group  rounded-lg shadow-xl shadow-neutral-100 ">
        {content.map((_, idx) => (
          <div className="p-2 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
            <Icon
              icon={_.icon}
              className="text-green-500 text-xl md:text-2xl shadow-xl shadow-neutral-100"
            />
            <p className=" text-xs font-medium text-slate-700 mt-1">{_.title}</p>
            <p className="mt-1 text-xs text-slate-500">{_.desc}</p>
          </div>
        ))}
      </div>
    </>
  );
}




const content = [
  {
    icon: "ic:baseline-gpp-good",
    title: "100%",
    desc: "Authentic",
  },
  {
    icon: "bi:send-check",
    title: "Free Shipping",
    desc: "Over $89+",
  },
  {
    icon: "healthicons:eco-care",
    title: "Fast Delivery",
    desc: "2-3 Days",
  },
 
];
import { Icon } from "@iconify/react";
import GreyBtn from "../../buttons/GreyBtn";
import { cn } from "../../../helpers/utils";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@nextui-org/progress";
import axios from "axios";
import Loader from "../../common/Loader/loader";
import DialogWrapper from "../../common/dialog-wrapper";
import DeleteProductModal from "./delete-product";
import Skeleton from "@mui/material/Skeleton";
import { Chip } from "@mui/material";

export const ProductCard = ({
  alt,
  info,
  isDelete = true,
}: {
  alt?: boolean;
  info: any;
  isDelete?: any;
}) => {
  const [data, setData] = useState();
  const [isUser, setIsUser] = useState(false);
  const [loading, setLoading] = useState(true);
  function getRandomNumber() {
    return Math.floor(Math.random() * (35 - 18 + 1)) + 18;
  }

  useEffect(() => {
    if (window.location.href.indexOf("my/") > -1) {
      setIsUser(true);
    }
    const fetchData = async (imgs: any) => {
      try {
        const response = await axios.get(
          `https://webservice.pairrit.com/pairrit-ws/api/getsignedurl?filenames=${imgs}`
        );
        setData(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
    if (info.condition !== "NEW") {
      fetchData(info.images.toString());
    } else {
      setData(info.images);
      setLoading(false);
    }
  }, [window]);
  return (
    <div className=" bg-white shadow-md rounded-lg duration-500 hover:scale-105 hover:shadow-xl ">
      <div className="h-full w-full bg-neutral-100 rounded-lg border backdrop-blur-[20px] relative">
        {isUser &&
          (isDelete === true ? (
            <DialogWrapper
              wrapperClassName=""
              className="absolute -top-4 w-6 drop-shadow aspect-square -right-2"
              content={<DeleteProductModal id={info.id} />}
            >
              <button className="hover:animate-spin w-7 aspect-square grid place-content-center bg-red-500 rounded-full">
                <Icon icon={"lucide:x"} className="text-white text-lg" />
              </button>
            </DialogWrapper>
          ) : null)}
        <Link to={`/single/listing/${info.postId}`}>
          {loading ? (
            <Skeleton
              className="my-2 mx-auto"
              variant="rounded"
              width={180}
              height={130}
            />
          ) : (
            <img
              className="h-full w-60 object-cover rounded-t-lg "
              src={data}
              alt=""
            />
          )}
        </Link>
      </div>
      <Link to={`/single/listing/${info.postId}`}>
        <div className="md:px-3 px-2 md:py-3 py-1">
          <div className="flex justify-between">
            <div className="text-center my-auto text-gray-400 md:mr-3 uppercase text-[9px] md:text-2xs">
              {" "}
              {info.condition} • {info.formula} •{" "}
              {info.size < 0.1 ? "SMPL" : `${info.size}oz`}
            </div>

            {/* <div className="ml-auto text-orange-600 hidden md:block">
              <Icon
                className={"md:text-lg text-base"}
                icon={"ic:twotone-gpp-good"}
              />
            </div>
            <div className=" text-red-600 hidden md:block">
              <Icon
                className={"md:text-lg text-base"}
                icon={"hugeicons:package-sent"}
              />
            </div> */}
            {info.condition !== "NEW" ? (
              <CircularProgress
                classNames={{
                  svg: "w-10 h-10 drop-shadow-md ",
                  indicator: "stroke-black",
                  track: "stroke-white/10",
                  value: "text-xs font-semibold text-black",
                }}
                size="sm"
                value={info.usage}
                showValueLabel={true}
              />
            ) : (
              <Chip
                icon={
                  <Icon className={"text-2xs"} icon={"hugeicons:sale-tag-01"} />
                }
                sx={{
                  bgcolor: "background.paper",
                  fontSize: "9px",
                }}
                className="text-2xs "
                color="primary"
                size="small"
                label={`${getRandomNumber()}0+ Sold`}
                variant="outlined"
              />
            )}
          </div>
          <p className="text-sm md:text-base font-semibold text-black truncate block capitalize">
            {info.name}
          </p>
          <div className="flex items-center">
            <p className="text-lg md:text-xl font-semibold text-black cursor-auto my-">
              ${info.price}
            </p>
            <p className="ml-3 text-2xs font-semibold text-red-500 ">
              -{getRandomNumber()}% OFF
            </p>

            <div className="ml-auto text-green-300">
              <Icon
                className={"md:text-3xl text-2xl"}
                icon={"flat-color-icons:plus"}
              />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

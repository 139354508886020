import { Fade } from "react-awesome-reveal";

const Marketplace = () => {
  return (
    <section className="wrapper mt-10 ">
      <div className="containBig flex-col  justify-center items-center gap-8">
        <div className="justify-center lg:flex-row flex-col items-center gap-4 w-full flex">
          <Fade
            triggerOnce
            direction="left"
            className=" max-w-[550px] w-full xl:max-w-[640px] 2xl:max-w-[670px]"
          >
            <img
              src="/assets/imgs/home/fragVerify2.png"
              className=" object-contain w-[30em]"
              alt=""
            />
          </Fade>
          <div className="flex justify-start items-start flex-col gap-3 ml-3">
            <Fade direction="right" triggerOnce cascade className="w-full">
              <h4 className="text-black font-bold text-xl sm:leading-[1.4] sm:text-[30px] xl:text-[35px] 2xl:text-[30px]">
                Why Trust Us?
              </h4>
              <p className="text-slate-700 font-medium text-sm 2xl:leading-[1.4] sm:text-lg xl:text-2xl 2xl:text-[16px]">
                At Pairrit, your satisfaction is our top priority! Our dedicated
                team is here to ensure you receive exceptional service every
                step of the way. We offer a carefully curated collection of
                luxurious, authentic perfumes and colognes, so you can shop
                with confidence knowing you're getting only the highest quality.
                Plus, when you meet our minimum purchase requirements, enjoy the
                added bonus of Fast and Free Shipping, delivered right to your
                door. We’re excited to serve you and help you find your perfect
                fragrance!
              </p>{" "}
            </Fade>
          </div>
        </div>
        <Fade direction="up" triggerOnce className="w-full">
          <div className="flex lg:flex-row flex-col justify-center w-full items-start mt-14">
            <div className="flex justify-start items-start lg:items-center flex-col gap-1 text-left lg:text-center py-4 w-full lg:w-auto lg:px-8">
              <img
                src="/assets/imgs/home/shopit8.png"
                className="w-full max-h-[100px] mb-1 object-contain self-center"
                alt=""
              />
              <h4 className="primaryGrText text-xl xl:text-[20px] font-bold">
                Marketplace
              </h4>
              <p className="text-slate-700 font-medium text-base xl:text-xl max-w-[380px]">
                Fragrance Marketplace with Low Fees
              </p>
            </div>
            <div className="flex border-y lg:border-x border-solid border-[#0000001F] justify-start items-start lg:items-center flex-col gap-1 text-left lg:text-center py-4 lg:border-y-0 w-full lg:w-auto lg:px-8">
              <img
                src="/assets/imgs/home/shopit7.png"
                className="w-full max-h-[100px] mb-1 object-contain self-center"
                alt=""
              />
              <h4 className="primaryGrText text-xl xl:text-[20px] font-bold">
                Perfume Oils
              </h4>
              <p className="text-slate-700 font-medium text-base xl:text-xl max-w-[380px]">
                Inspired Fragrance Oils
              </p>
            </div>
            <div className="flex justify-start items-start lg:items-center flex-col gap-1 text-left lg:text-center py-4 w-full lg:w-auto lg:px-8">
              <img
                src="/assets/imgs/home/shopit3.png"
                className="w-full max-h-[105px] mb-1 object-contain self-center"
                alt=""
              />
              <h4 className="primaryGrText text-xl xl:text-[20px] font-bold">
                Candles (Coming Soon!)
              </h4>
              <p className="text-slate-700 font-medium text-base xl:text-xl max-w-[380px]">
                Inspired Home Scents
              </p>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Marketplace;

import { Icon } from "@iconify/react";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import { ComponentProps, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { cn } from "../../../helpers/utils";
import Loader from "../../common/Loader/loader";

type CartCardProps = ComponentProps<"div"> & { data: any };

export function CartCard({ className, data, ...props }: CartCardProps) {
  const [pic, setPic] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (imgs: any) => {
      try {
        const response = await axios.get(
          `https://webservice.pairrit.com/pairrit-ws/api/getsignedurl?filenames=${imgs}`
        );
        setPic(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    if (data.condition !== "NEW") {
      fetchData(data.images.toString());
    } else {
      setPic(data.images);
      setLoading(false);
    }
  }, [data]);
  return (
    <>
      {loading ? (
        <Skeleton
          className="my-2 mx-auto"
          variant="rounded"
          width={90}
          height={75}
        />
      ) : (
        <Link to={`/single/listing/${data.postId}`}>
          <img
            className="rounded-lg flex flex-col items-center  h-full w-full"
            src={pic}
            alt=""
          />
        </Link>
      )}

      <div className="flex  md:gap-6 gap-3" {...props}>
        <Link
          className="text-xs max-md:leading-snug max-md:text-[8px]"
          to={`/single/listing/${data.postId}`}
        >
          <p className="font-semibold text-sm max-md:text-xs max-md:mb-1">
            {data.name}
          </p>
          <p className="text-neutral-400">
            Condition: <span className=" font-semibold">{data.condition}</span>
          </p>
          {/* <p className="text-neutral-400">
            Product code : <span className=" font-semibold">{data.id}</span>
          </p> */}
          <p className="text-neutral-400">
            Size:{" "}
            <span className=" font-semibold">
              {data.size < 0.1 ? "Sample" : `${data.size}ml`}
            </span>
          </p>
          <p className="text-neutral-400">
            Formula: <span className=" font-semibold">{data.formula}</span>
          </p>
        </Link>
      </div>
    </>
  );
}

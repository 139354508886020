import { Fade } from "react-awesome-reveal";
import GradientBtn from "../../buttons/GradientBtn";

const Hero = () => {
  return (
    <section>
      <div className="flex  justify-between items-center ">
        <div className="flex justify-start sm:text-left text-center w-full  items-start flex-col gap-3 sm:gap-5 drop-shadow-xl">
          <Fade triggerOnce cascade direction="left" className="w-full">
            <h4 className="font-bold text-black text-[20px] sm:leading-[1.3] sm:text-[30px] xl:text-[35px] 2xl:text-[35px] drop-shadow-xl">
              Why Pairrit?
            </h4>
            <p className="text-slate-900 font-medium xl:text-base text-[12px]">
              At Pairrit, we believe finding the perfect fragrance is a
              transformative experience. A great perfume or cologne isn’t just
              an accessory—it’s a way to express yourself, boost your
              confidence, and make every day feel special. But with high prices
              on brand-name fragrances, discovering new scents that truly
              resonate with you can feel out of reach. That’s why we created
              Pairrit – your go-to, affordable fragrance Marketplace. Enjoy
              a curated selection of authentic perfumes, colognes and perfume
              oils, offering you the chance to explore, experiment, and find
              your perfect scent!
              
            </p>
            <div className="flex justify-start w-full items-center gap-4 sm:flex-row flex-col">
              <GradientBtn className={"w-full"} label="Start Shopping" />
            </div>
          </Fade>
        </div>
        <Fade
          triggerOnce
          cascade
          direction="right"
          className="max-w-[550px] lg:max-w-[400px] xl:max-w-[500px] w-full"
        >
          <div className="w-full grid place-items-center relative">
            <img src="/assets/imgs/home/fragVerify1.png" />
            <img
              src="/assets/imgs/about/hero-vid-bg.png"
              className="lg:block hidden w-full object-cover absolute left-[51%] top-1/2 -translate-x-1/2 -translate-y-1/2 -z-10  "
              alt=""
            />
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Hero;

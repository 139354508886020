import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { ComponentProps, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import { province } from "../../../data/states";
import { cn } from "../../../helpers/utils";
import { useAppSelector } from "../../../redux/hooks";
import GradientBtn from "../../buttons/GradientBtn";
import Select from "../../common/select";
import { Loading } from "../../common/Widget/Loading";
import { Form, FormControl, FormField, FormItem } from "../../ui/form";
import { Action } from "./action";
import { BillingExt } from "./billing-ext";
import { Breadcrumb } from "./breadcrumb";
import { useOktaAuth } from "@okta/okta-react";
import { PaymentExt } from "./payment-ext";
import NotAdded from "../../common/Error/NotAdded";
import useAuthUser from "../../../hooks/getAuthUser";
import mixpanel from "mixpanel-browser";
import Cookies from "js-cookie";

const formSchema = z.object({
  buyerId: z.string().optional(),
  isMember: z.string().optional(),
  email: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  phone: z.string().optional(),
  address: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.coerce.number().optional(),
  deliveryMethod: z.string().optional(),
});

type InfoProps = ComponentProps<"div">;

export function Info({ className, ...props }: InfoProps) {
  const dispatch = useDispatch();
  const user = useAuthUser();

  const { authState } = useOktaAuth();
  const { userId } = useAppSelector((state) => state.userProfile);
  const feed = useAppSelector((state) => state.cart.cartData);

  const { profileData, loading } = useAppSelector((state) => state.userProfile);
  const [isLoading, setIsLoading] = useState(false);
  const [isCart, setIsCart] = useState<any>(
    feed.map(({ id, total }: { id: string; total: number }) => {
      return { productId: id, total: total };
    })
  );

  const [isUrl, setIsUrl] = useState("");
  const navigate = useNavigate();
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  // function onSubmit(values: any) {
  //   dispatch(addCheckoutProperties(values));
  //   return navigate("/checkout/payment");
  // }

  const checkout = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `https://webservice.pairrit.com/pairrit-ws/api/payment/getCheckoutSession`,
        {
          buyerId: authState?.isAuthenticated ? userId : Cookies.get("cartId"),
          isMember: authState?.isAuthenticated ? true : false,
          orderProductQuantityList: isCart,
        }
      );
      setIsUrl(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      return "error";
    }
    setIsLoading(false);
  };

  useEffect(() => {
    checkout();
  }, [feed]);
  useEffect(() => {
    mixpanel.track("Checkout page view", {
      email: user ? user["email"] : "",
    });
  }, []);

  return (
    <div className={cn(className)}>
      <Breadcrumb current="info" />
      <BillingExt />
      <br />
      {isLoading ? (
        <Loading />
      ) : (
        <Link
          onClick={() =>
            mixpanel.track("Checkout button clicked", {
              ...feed,
              checkout_type: authState?.isAuthenticated ? "Member" : "Guest",
              email: "value",
            })
          }
          to={isUrl}
        >
          <GradientBtn label={"Pay Now"} className="w-full" />
        </Link>
      )}
      <br />
      <h4 className="text-[16px] font-semibold my-2.5">
        We Pairrify All Sold Items
      </h4>
      <PaymentExt />
      <br />
    </div>
  );
}

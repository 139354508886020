import { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Loader from "../../../common/Loader/loader";
import { Chip } from "@mui/material";
import InfoSection from "./info-section";
import { useOktaAuth } from "@okta/okta-react";
import { fetchGearProducts, fetchSingleGear } from "../../../../redux/features/singlePages/newGear/newGearSlice";
import { ProductCard } from "../../Market/product-card";
import NotAdded from "../../../common/Error/NotAdded";
import MarketCategories from "../../Market/Categories";
import GearDetails, { RatingStars } from "./details";
import ProdDetails from "./prod-details";
import GearGallery from "./carousel";
import Card from "../../../common/Card/Card";
import { Icon } from "@iconify/react";
import { PaymentExt } from "../../Checkout/payment-ext";
import Carousel from "./carousel";
import DialogWrapper from "../../../common/dialog-wrapper";
import { CopyLink } from "../../My-Gear-Room/copy-link";
import { add2Wishlist } from "../../../../redux/features/userWishlist/wishlistSlice";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { GearCard } from "./gear-card";

export default function NewGearPage() {
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
  const user = authState?.idToken?.claims;
  const { userId } = useAppSelector((state) => state.userProfile);
  const { productList, similarList,error, postData, loading } = useAppSelector((state) => state.newGearPost);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(fetchSingleGear({ id: id, user: "Guest" }));
    }
 
  }, [id, authState]);

  const [currentProd, setCurrentProd] = useState("FULL");


function getRandomNumber() {
  return Math.floor(Math.random() * (35 - 18 + 1)) + 18;
}

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <NotAdded
          m1="Nothing to Find"
          m2="Fragrance Not Found"
          m3="Error with finding Fragrance"
        />
      ) : (
        <section className="mb-[8em]">
          {/* <img
            className="md:w-full md:h-[6em] mx-auto mb-2 md:mb-4 object-cover md:mt-2 rounded-lg"
            src="/assets/imgs/home/banner-2.png"
            alt="ad"
          /> */}
          <div className="flex flex-col md:flex-row w-full md:gap-4 lg:gap-2 md:mt-2">
            <GearGallery
              item={false}
              lg={12}
              md={12}
              imgs={postData.img.split()}
            />

            <div className="lg:w-[40em] md:w-[25em]">
              {productList?.length != 0 ? (
                productList
                  .filter((obj: any) => {
                    return obj.sizeCategory === currentProd;
                  })
                  .map((el: any, idx: any) => {
                    return (
                      <Card
                        noMt
                        className="bg-white flex flex-col max-lg:py-4 gap-1   p-4"
                      >
                        {" "}
                        <div className="flex justify-between">
                          <div className="lg:text-2xl text-lg font-extrabold uppercase text-left">
                            {postData.model}{" "}
                            <span className="font-light text-zinc-500 italic">
                              {postData.formula !== "OIL"
                                ? "Inspired"
                                : postData.brandAbr}
                            </span>
                          </div>

                          <Chip
                            icon={
                              <Icon
                                className={"text-2xs max-md:hidden"}
                                icon={"hugeicons:sale-tag-01"}
                              />
                            }
                            sx={{
                              bgcolor: "background.paper",
                              fontSize: "10px",
                            }}
                            className="text-2xs max-md:hidden"
                            color="default"
                            size="small"
                            label={`Luxury`}
                            variant="outlined"
                          />
                        </div>
                        {el.product == null ? (
                          <Card
                            noMt
                            className="bg-slate-200 lg:my-3 h-[16.25em]"
                          >
                            <NotAdded
                              m1="Restocking Soon "
                              m3={`SIZE ${currentProd} IS OUT`}
                              m2="See Other Sizes"
                            />
                          </Card>
                        ) : (
                          <ProdDetails
                            info={el}
                            isListing={true}
                            isWatchlist={postData.inWishlistIds}
                            offersMade={1}
                          />
                        )}
                        <p className="font-medium text-center mt-3">
                          Luxury Perfume Oils{" "}
                        </p>
                        <MarketCategories
                          setValue={setCurrentProd}
                          value={currentProd}
                          selection={postData.prices}
                        />
                        {/* <p className="font-medium text-center mt-2">
                          Perfume Oils - 6+ hours
                        </p>
                        <MarketCategories
                          setValue={setCurrentProd}
                          value={currentProd}
                          selection={postData.sizes}
                        /> */}
                        <PaymentExt />
                        <GearDetails info={postData} />
                      </Card>
                    );
                  })
              ) : (
                <NotAdded
                  m1="Nothing to Find"
                  m2=" No Orders Yet"
                  m3="Start Selling to earn FlexPoints"
                />
              )}
            </div>
          </div>

          {/* <Rates info={postData} /> */}

          <InfoSection info={postData} />

          <section>
            <div className="bg-white border rounded-lg mt-3 border-slate-400 border-opacity-20 p-2">
              {" "}
              <div className="capitalize text-center">More Perfume Oils</div>
            </div>
            {similarList?.length != 0 ? (
              <div className="mt-6 grid grid-cols-2 gap-2 z-40 lg:grid-cols-4 md:grid-cols-4 xl:grid-cols-6">
                {similarList.map((_: any, key: any) => (
                  <div>
                    <GearCard key={key} info={_} />
                  </div>
                ))}
              </div>
            ) : (
              <NotAdded
                m1="Nothing to Find"
                m2=" No Products Yet"
                m3="Start Listing to earn FlexPoints"
              />
            )}
          </section>
        </section>
      )}
    </>
  );
}

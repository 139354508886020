import { styled } from "@mui/material";
import Slider, { SliderThumb } from "@mui/material/Slider";
import React from "react";
import { useSearchParams } from "react-router-dom";
import {
  adjustPrice,
} from "../../../redux/features/market/marketplaceSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import SearchSelect from "../../common/search-select";
import { addExistingParamsValue, } from "./filter-mob-full";

export const searchFilterData = [
  {
    label: "Gender",
    options: [
      { label: "Unisex", value: "UNISEX" },
      { label: "Female", value: "FEMALE" },
      { label: "Male", value: "MALE" },
    ],
    // function: conditionSelect,
  },

  {
    label: "Brands",
    options: [],
    // function: brandSelect,
  },
  {
    //Prev:'Instrument' Or 'Category'//
    label: "Category",
    options: [
      { label: "Floral", value: "FLORAL" },
      { label: "Fresh", value: "FRESH" },
      { label: "Fruity", value: "FRUITY" },
      { label: "Gourmond", value: "GOURMOND" },
      { label: "Aromatic", value: "AROMATIC" },
      { label: "Oriental", value: "ORIENTAL" },
      { label: "Woody", value: "WOODY" },
      { label: "Leather", value: "LEATHER" },
    ],
    // function: categorySelect,
  },

  // {
  //   label: "Rate",
  //   options: [],
  //   extra: <MinimumDistanceSlider step={1} min={0.0} max={5.0} type="rate" />,
  //   // function: sizeSelect,
  // },
  {
    label: "Formula",
    options: [
      { label: "Eau Fraiche", value: "EF" },
      { label: "Eau De Cologne", value: "EDC" },
      { label: "Eau De Toilette", value: "EDT" },
      { label: "Eau De Parfume", value: "EDP" },
      { label: "Parfume", value: "PAR" },
    ],
    // function: formulaSelect,
  },
  {
    label: "Year",
    options: [],
    extra: (
      <MinimumDistanceSlider
        step={10}
        min={1960}
        max={2024}
        typeA="minYear"
        typeB="maxYear"
      />
    ),
    // function: sizeSelect,
  },
  {
    label: "Price",
    options: [],
    extra: (
      <MinimumDistanceSlider
        step={8}
        min={20}
        max={400}
        typeA="minPrice"
        typeB="maxPrice"
      />
    ),
    // function: priceSelect,
  },
  // {
  //   label: "Accepts Offers",
  //   options: [],
  //   function: categorySelect,
  // },
];

function valuetext(value: number) {
  return `${value}`;
}

export default function MinimumDistanceSlider({
  max,
  min,
  typeA,
  typeB,
  step,
}: {
  max: any;
  min: any;
  typeA: any;
  typeB: any;
  step: any;
  }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const maxYear = searchParams.get(typeB);
  const minYear = searchParams.get(typeA);
  const { price } = useAppSelector((state) => state.marketplace);
  const [value1, setValue1] = React.useState<number[]>([
    minYear ? minYear : min,
    maxYear ? maxYear : max,
  ]);
  const dispatch = useAppDispatch();

  const handleChange1 = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
       
       setSearchParams(
         addExistingParamsValue(searchParams, typeA, newValue[0])
       );
      setValue1([Math.min(newValue[0], value1[1] - 0), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + 0)]);
      setSearchParams(
        addExistingParamsValue(searchParams, typeB, newValue[1])
      );
      
    }
  };

  return (
    <AirbnbSlider
      slots={{ thumb: AirbnbThumbComponent }}
      getAriaLabel={() => "Price Range"}
      value={value1}
      step={step}
      marks
      onChange={handleChange1}
      min={min}
      valueLabelFormat={valuetext}
      max={max}
      valueLabelDisplay="on"
      disableSwap
    />
  );
}
const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "#ff8589",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 27,
    width: 27,
    backgroundColor: "#ddd",
    border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(128, 13, 17, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {}

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

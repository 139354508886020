import { Icon } from "@iconify/react";
import { Link, useParams } from "react-router-dom";
import friendlyTime from "../../../../helpers/friendlyTime";
import GradientBtn from "../../../buttons/GradientBtn/GradientBtn";
import GreyBtn from "../../../buttons/GreyBtn/GreyBtn";
import Card from "../../../common/Card/Card";
import DialogWrapper from "../../../common/dialog-wrapper";
import NotifyButton from "../../../common/Notification/NotifyButton";
import Vote from "../Topic/vote";
import { useOktaAuth } from "@okta/okta-react";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import {
  add2Cart,
  add2CartSession,
  addProd2Cart,
} from "../../../../redux/features/cart/cartSlice";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { CopyLink } from "../../My-Gear-Room/copy-link";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../hooks/getAuthUser";
import { Info } from "../../Checkout/info";
import Quantity from "../Listing/quantity";
import MakeAnOffer from "../Listing/make-an-offer";
import { add2Wishlist } from "../../../../redux/features/userWishlist/wishlistSlice";
import Cookies from "js-cookie";
import { generateUUID } from "../../../../helpers/getUUID";
import NewGear from "../../Create-Post/NewGear";

interface CartType {
  name: string;
  id: string;
  postId: string;
  category: string;
  price: number;
  total: number;
  quantity: number;
  size: number;
  formula: string;
  condition: string;
  images: string;
}

export default function ProdDetails({
  info,
  isListing,
  isWatchlist,
  offersMade,
}: {
  info?: any;
  offersMade?: any;
  isListing?: boolean;
  isWatchlist?: any
}) {
  const { authState } = useOktaAuth();
  const { id } = useParams();
  const { cartData, fetchLoading } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const { userId } = useAppSelector((state) => state.userProfile);
  const [isWishlist, setIsWishlist] = useState(
    isWatchlist?.includes(userId) ? true : false
  );
  const [amount, setAmount] = useState(1);
  const exists = cartData.some((e: any) => e.id == info.product.id);
  const [inCart, setInCart] = useState(exists);
  const [cartDetails, setCartDetails] = useState<CartType>({
    name: info.product.name,
    id: info.product.id,
    postId: info.product.postId,
    category: info.product.category,
    price: info.product.price,
    total: amount,
    quantity: info.product.quantity,
    size: info.product.size,
    formula: info.product.formula,
    condition: info.product.condition,
    images: `${info.condition === "NEW" ? info.gear.img : info.product.images}`
  });
  useEffect(() => {
    if (isListing) {
      mixpanel.track("Product detail page view", {
        product_name: info.product.name,
        product_id: info.product.id,
        listing_id: info.product.postId,
        product_amount: info.product.price,
        email: user ? user["email"] : "",
      });
    }
    setCartDetails({
      name: info.product.name,
      id: info.product.id,
      postId: info.product.postId,
      category: info.product.category,
      price: info.product.price,
      total: amount,
      quantity: info.product.quantity,
      size: info.product.size,
      formula: info.product.formula,
      condition: info.product.condition,
      images: `${
        info.condition === "NEW" ? info.gear.img : info.product.images
      }`,
    });
    if (isWatchlist?.includes(userId)) {
      setIsWishlist(true);
    }
  }, [cartData, amount, isWatchlist]);

  const onSubmit = () => {
    if (!cartData.some((e: any) => e.id == info.product.id)) {
      if (authState?.isAuthenticated) {
        dispatch(
          add2Cart({
            userId: userId,
            productId: info.product.id,
            qty: amount,
          })
        ).then(() =>
          dispatch(
            notifySelect({
              title: "Item Added!",
              type: 0,
              open: true,
            })
          )
        );
        dispatch(addProd2Cart(cartDetails));
        mixpanel.track("Add product to cart", {
          product_name: info.product.name,
          product_id: info.product.id,
          listing_id: info.product.postId,
          product_amount: info.product.price,
          email: user ? user["email"] : "",
        });
         setInCart(true);
      } else {
        if (Cookies.get("cartId")) {
          dispatch(
            add2CartSession({
              userId: Cookies.get("cartId"),
              productId: info.product.id,
              qty: amount,
            })
          ).then(() =>
            dispatch(
              notifySelect({
                title: "Item Added!",
                type: 0,
                open: true,
              })
            )
          );
        } else {
          const uuid = generateUUID();
          Cookies.set("cartId", uuid, {
            expires: 3,
            path: "/",
            secure: true,
            sameSite: "None",
          });
          dispatch(
            add2CartSession({
              userId: uuid,
              productId: info.product.id,
              qty: amount,
            })
          ).then(() =>
            dispatch(
              notifySelect({
                title: "Item Added!",
                type: 0,
                open: true,
              })
            )
          );
        }
        dispatch(addProd2Cart(cartDetails));
        setInCart(true);
      }
    } else {
      dispatch(
        notifySelect({
          title: "Check Your Cart",
          type: 2,
          open: true,
        })
      );
    }
  };

  const add2list = () => {
    if (authState?.isAuthenticated) {
      dispatch(add2Wishlist({ prodId: id, userId: userId })).finally(() =>
      setIsWishlist(true)
      );
    } else {
      dispatch(
        notifySelect({
          title: "Please Signin",
          type: 1,
          open: true,
        })
      );
    }
  };

  useEffect(() => {
    if (exists) {
      setInCart(true);
    }
  }, [info.product]);

  // Make sure its a listing
  return (
    <Card noMt className="bg-white flex flex-col shadow  mb-2">
      <div className="justify-center ">
        <div className="flex items-center gap-4 md:mb-4 my-1">
          <p className="text-[25px] font-semibold">
            Price:{" "}
            <span className="text-red-500">
              $ {info.product.price.toFixed(2)}
            </span>
          </p>{" "}
          {/* <p className="text-lg line-through text-neutral-400">$ 918</p> */}
        </div>

        <div className="text-xs font-medium text-neutral-400 [&>b]:pl-1 [&>b]:pr-2 md:mt-3">
          <b className="text-red-500">{info.product.quantity} Left</b> Type:{" "}
          <b>{info.product.category} </b>
          Size:{" "}
          <b>{info.product.size < 0.1 ? "SMPL" : `${info.product.size}ml`} </b>
          Formula: <b>{info.product.formula} </b>
        </div>

        <div className="flex gap-3 [&_*]:text-black items-center mt-2 lg:mt-6 w-full ">
          <Quantity
            max={info.product.quantity}
            setValue={setAmount}
            postId={info.product.postId}
            value={amount}
          />{" "}
          {info.product.status === "active" ? (
            inCart ? (
              <GradientBtn
                label={"Already in cart"}
                iconClassName="text-xl text-green-500"
                iconify="icon-park:check-one"
                onClick={() =>
                  dispatch(
                    notifySelect({
                      title: "Already in cart",
                      type: 2,
                      open: true,
                    })
                  )
                }
                className={"w-full [&_*]:!text-white font-bold"}
              />
            ) : (
              <GradientBtn
                label={"Add to Cart"}
                iconClassName="text-xl text-green-500"
                iconify="bi:cart-plus-fill"
                onClick={() => onSubmit()}
                className={"w-full [&_*]:!text-white font-bold"}
              />
            )
          ) : (
            <GradientBtn
              label={"Sold Out"}
              className={
                "w-full [&_*]:!text-white opacity-25 hover:opacity-25 font-bold"
              }
            />
          )}
        </div>
        <div className="flex mt-4 gap-3">
          <button
            onClick={add2list}
            className=" border text-center w-full rounded-xl shadow-sm h-9 text-xs font-medium flex items-center justify-center rounded-md bg-accentGray-400 hover:bg-accentGray-500 text-accentGray-700 gap-1.5 transition-colors  border-solid border-[rgba(134, 145, 168, 0.20)]"
          >
            {isWishlist ? "Remove from watchlist " : "Add to Watchlist"}
          </button>
          <DialogWrapper className="z-30 w-full" content={<NewGear />}>
            <button className=" gap-1.5 text-white rounded-xl px-3 h-10 flex items-center justify-center hover:opacity-75 shadow border-2 border-white shadow w-full [&_*]:!text-white bg-gray-400">
              <Icon className="text-xl" icon="flat-color-icons:plus" />
              <p className="md:text-xs font-semibold leading-tight text-white capitalize text-xs w-full">
                Add to Collection
              </p>
            </button>
          </DialogWrapper>
        </div>

        <NotifyButton
          className={"w-full mt-3"}
          placeholder="Original bottle not included with sample/decant purchase. Pairrit rebottles the genuine fragrance into smaller bottles."
        />
      </div>
    </Card>
  );
}
export function RatingStars({ numberOfReviews }: { numberOfReviews?: string }) {
  return (
    <div className="flex items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4 text-yellow-500"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
      {numberOfReviews && (
        <p className="text-gray-600 font-bold text-xs ml-">
          {`${numberOfReviews}`}
        </p>
      )}
    </div>
  );
}

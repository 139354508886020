import React from "react";
import { Link, useLocation } from "react-router-dom";
import Card from "../../../common/Card/Card";
import GradientBtn from "../../../buttons/GradientBtn";
import RegisterForm from "../../../auth/RegisterForm";
import DialogWrapper from "../../../common/dialog-wrapper";
import mixpanel from "mixpanel-browser";
import JoinWaitList from "../../../common/Sidebar/JoinWaitlist";

const ListFlags = ({ shop }: any) => {
  
  return (
    <Card
      noMt
      className="bg-gradient-to-r from-zinc-200 to-slate-300 rounded-xl shadow-lg flags p-0 h-[12em]"
    >
      <div className="grid grid-cols-12 md:gap-5">
        <div className="md:order-1 lg:col-span-4 md:col-span-3 hidden md:block">
          <img
            className="h-[12em] w-full object-cover "
            src="/assets/imgs/home/shopit2.png"
            alt=""
          />
        </div>
        <div className="lg:col-span-4 md:col-span-6 col-span-8 items-center  order-2 flex flex-col mx-auto pt-4">
          <p className="md:text-4xl text-3xl font-bold pb-2  bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-red-700 ">
            {shop ? "Join Now!" : "Earn Selling"}
          </p>
          <p className="text-xs font-semibold ">
            {" "}
            {shop
              ? "First 100 Earn 50% Off Fees For LIFE!"
              : "Join the biggest fragrance community!"}
          </p>

          <div className="flex gap-3 mt-3">
            {/* {!shop ? (
              <Link
                className="my-2"
                to="/signup"
                onClick={() =>
                  mixpanel.track("Register button clicked", {
                    screen: "create listing",
                  })
                }
              >
                <GradientBtn
                  textClassName="text-sm"
                  className={"w-full px-10 rounded-full"}
                  label={"Register Here"}
                />
              </Link>
            ) : (
              <Link
                className="my-2"
                to="/settings/payment"
                onClick={() =>
                  mixpanel.track("Open shop button clicked", {
                    screen: shop ? "slider" : "create listing",
                  })
                }
              >
                <GradientBtn
                  className={"w-full px-10 rounded-full"}
                  label={"Add Payout Method"}
                />
              </Link>
            )} */}
            <DialogWrapper content={<JoinWaitList />}>
              <GradientBtn
                className={"w-full rounded-lg"}
                label={"Join Seller list"}
                onClick={() => {
                  mixpanel.track("Join waitlist clicked", {
                    screen: "create listing",
                  });
                }}
              />
            </DialogWrapper>
          </div>
        </div>
        <div className="order-2 lg:col-span-4 md:col-span-3 col-span-4 start-end">
          <img
            className=" h-[12em] w-full object-cover bg-transparent  "
            src="/assets/imgs/home/shopit.png"
            alt=""
          />
        </div>
      </div>
    </Card>
  );
};

export default ListFlags;

import { Icon } from "@iconify/react";
import { CircularProgress } from "@nextui-org/progress";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DialogWrapper from "../../../common/dialog-wrapper";
import Loader from "../../../common/Loader/loader";
import { RatingStars } from "../../Single-Page/Listing/details";
import { feedbackInputAssets2 } from "../Purchases/feedbackinputs";
import FeedbackForm from "../Purchases/FeedbackModal";
import TrackingForm from "../Purchases/TrackingModal";

const OrderCard = ({ el, idx }: { el: any; idx: number }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (imgs: any) => {
      try {
        const response = await axios.get(
          `https://webservice.pairrit.com/pairrit-ws/api/getsignedurl?filenames=${imgs}`
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
  
     if (el.product.condition !== "NEW") {
       fetchData(el.product.images.toString());
     } else {
       setData(el.product.images);
       setLoading(false);
     }
  }, []);

  return (
    <div
      className="border rounded-lg border-solid bg-white border-accentGray-600 mb-4"
      key={"purchase-" + idx}
    >
      <div className="p-[10px]">
        <div className="flex flex-wrap gap-4 justify-between items-center pb-[10px]">
          <div className="flex gap-8">
            <div
              className={`capitalize w-[100px] text-${
                el.orderStatus === "Open" ? "accentBlue-500" : "red-600"
              } font-semibold text-sm cursor-pointer`}
            >
              {el.orderStatus}
            </div>
            <div className="text-sm font-semibold">
              {el.purchaseDate.split("T")[0]}
            </div>
          </div>
          <div className="flex gap-4">
            <div className="hidden sm:flex cursor-pointer text-accentBlue-500 gap-2 items-center text-xs font-semibold">
              <img src="/assets/vectors/purchases/message.svg" alt="message" />
              Messages
            </div>

            <div className="text-xs">
              Total:{" "}
              <span className="text-accentBlue-500 font-semibold">
                ${el.totalAmount.toFixed(2)}
              </span>
            </div>
          </div>
        </div>

        <div className="flex gap-1 grid md:space-x-4 space-y-2 grid-cols-12 py-[5px] border-t border-solid border-accentGray-600">
          <Link
            className="col-span-3 text-accentBlue-500 font-semibold text-sm mt-2"
            to={`/single/listing/${el.product.postId}`}
          >
            {loading ? (
              <Loader />
            ) : (
              <img
                className="rounded-lg object-cover aspect-square h-[124px] w-full"
                src={data}
                alt=""
              />
            )}
          </Link>
          <Link
            className="col-span-5"
            to={`/single/listing/${el.product.postId}`}
          >
            <div className="font-semibold text-sm">{el.product.name}</div>
            <div className="text-accentGray-700 text-xs font-medium uppercase">
              Order Number: {el.purchaseId}
            </div>
            <div className="text-accentGray-700 text-xs font-medium">
              Quantity: {el.quantity}
            </div>
            <div className="text-accentGray-700 text-xs font-medium">
              Condition: {el.product.condition}
            </div>
          </Link>

          <div className="col-span-4">
            <div className="font-bold text-xs">Shipping info:</div>
            <div className="text-xs font-medium  mt-[5px]">
              Pairrit Shop
            </div>
            <div className="text-xs font-medium  ">83 Windham St.</div>
            <div className="text-xs font-medium mb-[1px]">ntic, CT 06226</div>
            <a href={"tel:" + el.tel} className="text-xs text-accentBlue-500">
              Tel: 860-833-1538
            </a>
          </div>
        </div>

        <div className="py-[10px] flex justify-between items-center border-t border-b border-solid border-accentgray-600 gap-4 flex-wrap md:flex-nowrap">
          <div className="text-xs flex flex-shrink-0 items-center gap-2">
            <img src="/assets/vectors/purchases/shipped.svg" alt="shipped" />
            Shipped to us:
            {el.purchaseDate ? (
              <Icon
                icon={"lets-icons:check-fill"}
                className="text-green-500 text-lg"
              />
            ) : (
              <Icon
                icon={"ic:baseline-pending"}
                className="text-red-500 text-lg"
              />
            )}
          </div>
          <div className="text-xs flex flex-shrink-0 items-center gap-2">
            <img src="/assets/vectors/purchases/shipped.svg" alt="shipped" />
            Shipped to buyer:
            {el.shippedOutDate ? (
              <Icon
                icon={"lets-icons:check-fill"}
                className="text-green-500 text-lg"
              />
            ) : (
              <Icon
                icon={"ic:baseline-pending"}
                className="text-red-500 text-lg"
              />
            )}
          </div>

          <div className="gap-2 flex font-medium justify-between">
            {el.buyerFeedBack ? (
              <div className="text-xs flex flex-shrink-0 items-center gap-2">
                Your Feedback:
                <RatingStars numberOfReviews={el.buyerFeedBack} />
              </div>
            ) : (
              <DialogWrapper
                content={
                  <FeedbackForm
                    id={el.purchaseId}
                    order
                    inputAssets={feedbackInputAssets2}
                  />
                }
              >
                <button className="align-start border border-solid bg-accentGray-400 border-accentGray-600 rounded-md h-9 px-4  hover:-translate-y-1 transition-transform text-accentGray-200 text-xs">
                  Leave Review
                </button>
              </DialogWrapper>
            )}
            <DialogWrapper content={<TrackingForm id={el.purchaseId} />}>
              <button className="align-start border border-solid bg-accentGray-400 border-accentGray-600 rounded-md h-9 px-4  hover:-translate-y-1 transition-transform text-accentGray-200 text-xs">
                {el.trackingNumber ? "Update Tracking" : "Add Tracking"}
              </button>
            </DialogWrapper>
          </div>
        </div>
        <div className="py-[10px] flex flex-wrap gap-4 justify-between items-center border-b border-solid border-accentgray-600">
          <div className="text-xs gap-2">
            Payments completed: {el.purchaseDate.split("T")[0]}
          </div>
          {el.trackingNumber && (
            <Link
              className="text-xs gap-2"
              target="_blank"
              rel="noopener noreferrer"
              to={`https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=${el.trackingNumber}`}
            >
              Tracking Number: {el.trackingNumber}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderCard;

import { Popover } from "@headlessui/react";
import mixpanel, { track } from "mixpanel-browser";
import { Plus } from "phosphor-react";
import { Link } from "react-router-dom";
import useAuthUser from "../../../hooks/getAuthUser";
import { WidgetForm } from "../Widget/WidgetForm";
import { PostWidgetForm } from "./PostWidgetForm";

const PostWidget = () => {
  const user = useAuthUser();
  return (
    <Popover className="z-50 fixed bottom-[1em] md:bottom-[5em] right-4  md:right-8 flex flex-col items-end md:hidden">
      <Link
        onClick={() => {
          mixpanel.track("Post widget clicked", {
            email: user ? user["email"] : "",
          });
        }}
        to="/create/sell"
      >
        <button className="mobile-post-btn rounded-full px-3 h-10 text-white flex items-center group">
          {/* create a group */}
          <Plus className="w-8 h-6" />
          <span className=" ease-linear">
            {/* on hover increase width */}
            {/* any element that overflows the max-width will be hidden */}
            <span className="pl-1"></span>
            Join Sellers Waitlist
          </span>
        </button>{" "}
      </Link>
    </Popover>
  );
};

export default PostWidget;

import React from 'react'
import { Link } from 'react-router-dom';
import CardContainer from './CardContainer';
import Flags from "../Home/Flags/Flags";
import Flags2 from "../Home/Flags/Flags2";
import ProductList from "./product-list";
import GearList from "../Single-Page/New-Gear/gear-list";
import { Icon } from "@iconify/react";

const MenShop = () => {
  return (
    <div>
      <Flags
        m1="Smell Like A King"
        m2="Save over 25% on all popular scents"
        m3="Shop All Men"
        m4={6}
        m5="?gender=MALE"
      />
      <Flags2
        m1="Smell Like A King"
        m2="Save over 25% on all popular scents"
        m3="Shop All Men"
        m4={7}
        m5="?gender=MALE"
      />
      <div className="lg:mt-10 mt-4">
        <Link to={"/market?gender=MALE"}>
          <p className="text-sm md:text-base font-bold flex mb-1">
            Shop All Mens{" "}
            <Icon
              icon="ph:arrow-right-duotone"
              className="my-auto ml-1 text-xl"
            />
          </p>
        </Link>

        <div className="h-0 mb-5  border-t border-slate-400/20" />
        <CardContainer />
      </div>
      <div className="lg:mt-18 mt-4">
        <p className="text-sm md:text-base font-bold flex">
          Trending Fragrances
          <Icon
            icon="ph:arrow-right-duotone"
            className="my-auto ml-1 text-xl"
          />
        </p>
        <div className="h-0  border-t border-slate-400/20" />
        <ProductList
          value={list}
          className="md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 "
        />
      </div>

      <img
        className="w-full h-full mx-auto object-cover mt-12 rounded-lg"
        src="/assets/imgs/home/banner-8.png"
        alt="ad"
      />
      <div className="lg:mt-20 mt-4">
        <p className="text-sm md:text-base font-bold flex ">
          Most Popular Scents
          <Icon
            icon="ph:arrow-right-duotone"
            className="my-auto ml-1 text-xl"
          />
        </p>
        <div className="h-0  border-t border-slate-400/20" />
        <GearList
          value={list1}
          className="md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 "
        />
      </div>
    </div>
  );
}

export default MenShop;

const list = [
  {
    id: "PRD100229",
    postId: "ITM100199",
    name: "Green Irish Tweed",
    price: 269.95,
    sellerId: "US100026",
    size: 3.4,
    usage: 100,
    formula: "EDP",
    images: "https://fimgs.net/mdimg/perfume/375x500.474.jpg",
    condition: "NEW",
  },
  {
    id: "PRD100418",
    postId: "ITM100388",
    name: "Y Le Parfum",
    price: 109.99,
    sellerId: "US100026",
    size: 3.4,
    usage: 100,
    formula: "PAR",
    images: "https://fimgs.net/mdimg/perfume/375x500.64718.jpg",
    condition: "NEW",
  },
  {
    id: "PRD100040",
    postId: "ITM100006",
    name: "Sauvage Elixir Dior",
    price: 141.99,
    sellerId: "US100025",
    size: 3.4,
    usage: 100,
    formula: "PAR",
    images: "https://fimgs.net/mdimg/perfume/375x500.68415.jpg",
    condition: "NEW",
  },
  {
    id: "PRD100821",
    postId: "ITM100791",
    name: "The One",
    price: 45.99,
    sellerId: "US100019",
    size: 3.4,
    usage: 100,
    formula: "EDP",
    images: "https://fimgs.net/mdimg/perfume/375x500.2056.jpg",
    condition: "NEW",
  },
  {
    id: "PRD100440",
    postId: "ITM100410",
    name: "Althaïr",
    price: 299.99,
    sellerId: "US100019",
    size: 4.2,
    usage: 100,
    formula: "EDP",
    images: "https://fimgs.net/mdimg/perfume/375x500.84109.jpg",
    condition: "NEW",
  },
  {
    id: "PRD100298",
    postId: "ITM100268",
    name: "1 Million Lucky",
    price: 129.99,
    sellerId: "US100019",
    size: 3.4,
    usage: 100,
    formula: "EDT",
    images: "https://fimgs.net/mdimg/perfume/375x500.48903.jpg",
    condition: "NEW",
  },
  {
    id: "PRD100638",
    postId: "ITM100608",
    category: "ORIENTAL",
    name: "Stronger With You Absolutely",
    usage: 100,
    price: 119.99,
    sellerId: "US100028",
    size: 3.4,
    images: "https://fimgs.net/mdimg/perfume/375x500.64501.jpg",
    condition: "NEW",
    formula: "EDP",
  },
  {
    id: "PRD100657",
    postId: "ITM100627",
    category: "ORIENTAL",
    name: "Armani Code Profumo",
    usage: 100,
    price: 79.99,
    sellerId: "US100028",
    size: 2,
    images: "https://fimgs.net/mdimg/perfume/375x500.34761.jpg",
    condition: "NEW",
    formula: "EDP",
  },
  {
    id: "PRD100707",
    postId: "ITM100677",
    category: "ORIENTAL",
    name: "A*Men Pure Havane",
    usage: 100,
    price: 29.99,
    sellerId: "US100029",
    size: 0.05,
    images: "https://fimgs.net/mdimg/perfume/375x500.11444.jpg",
    condition: "NEW",
    formula: "EDT",
  },
  {
    id: "PRD101045",
    postId: "ITM101015",
    category: "ORIENTAL",
    name: "Amore Caffe",
    usage: 100,
    price: 179.99,
    sellerId: "US100038",
    size: 4,
    images: "https://fimgs.net/mdimg/perfume/375x500.87409.jpg",
    condition: "NEW",
    formula: "EDP",
  },
  {
    id: "PRD101163",
    postId: "ITM101133",
    category: "ORIENTAL",
    name: "Bade'e Al Oud Oud for Glory",
    usage: 100,
    price: 34.99,
    sellerId: "US100040",
    size: 3.4,
    images: "https://fimgs.net/mdimg/perfume/375x500.64948.jpg",
    condition: "NEW",
    formula: "EDP",
  },
  {
    id: "PRD101186",
    postId: "ITM101156",
    category: "ORIENTAL",
    name: "Oud Pashmina",
    usage: 100,
    price: 139.99,
    sellerId: "US100040",
    size: 3.4,
    images: "https://fimgs.net/mdimg/perfume/375x500.64641.jpg",
    condition: "NEW",
    formula: "EDP",
  },
  {
    id: "PRD100134",
    postId: "ITM100106",
    category: "ORIENTAL",
    name: "Y By YSL Le Parfum",
    usage: 100,
    price: 83.39,
    sellerId: "US100016",
    size: 2,
    images: "https://fimgs.net/mdimg/perfume/375x500.64718.jpg",
    condition: "NEW",
    formula: "PAR",
  },
  {
    id: "PRD100975",
    postId: "ITM100945",
    category: "ORIENTAL",
    name: "Vanilla 28",
    usage: 100,
    price: 112.99,
    sellerId: "US100037",
    size: 1.7,
    images: "https://fimgs.net/mdimg/perfume/375x500.52616.jpg",
    condition: "NEW",
    formula: "EDP",
  },
];

const list1 = [
  {
    id: "FRG100266",
    brandName: "Creed",
    brandAbr: "CREED",
    model: "Aventus",
    img: "https://fimgs.net/mdimg/perfume/375x500.9828.jpg",
    fragUrl: "https://www.fragrantica.com/perfume/Creed/Aventus-9828.html",
    formula: "EDP",
    year: 2010,
    category: "FRUITY",
    popularRate: 9.3,
    status: "OG",
    gender: "MALE",
    rate: 4.47,
    price: 310.95,
    votes: 20802,
    createdDateTime: "2024-09-25T01:02:14.495",
  },
  {
    _id: "FRG100024",
    brandName: "Yves Saint Laurent",
    brandAbr: "YSL",
    model: "La Nuit de l'Homme",
    img: "https://fimgs.net/mdimg/perfume/375x500.5521.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Yves-Saint-Laurent/La-Nuit-de-l-Homme-5521.html",
    formula: "EDT",
    year: 2009,
    category: "WOODY",
    popularRate: 9.45,
    status: "OG",
    gender: "MALE",
    rate: 4.55,
    price: 173.05,
    votes: 25906,
    createdDateTime: "2024-09-25T00:50:37.41",
  },
  {
    _id: "FRG100051",
    brandName: "Versace",
    brandAbr: "VERSACE",
    model: "Eros",
    img: "https://fimgs.net/mdimg/perfume/375x500.16657.jpg",
    fragUrl: "https://www.fragrantica.com/perfume/Versace/Eros-16657.html",
    formula: "EDT",
    year: 2012,
    category: "AROMATIC",
    popularRate: 9.1,
    status: "CLASSIC",
    gender: "MALE",
    rate: 4.3,
    price: 39.99,
    votes: 30792,
    createdDateTime: "2024-10-22T11:13:02.514",
  },
  {
    _id: "FRG100086",
    brandName: "Chanel",
    brandAbr: "CHANEL",
    model: "Bleu de Chanel EDP",
    img: "https://fimgs.net/mdimg/perfume/375x500.25967.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Chanel/Bleu-de-Chanel-Eau-de-Parfum-25967.html",
    formula: "EDP",
    year: 2014,
    category: "FRESH",
    popularRate: 9.3,
    status: "OG",
    gender: "MALE",
    rate: 4.64,
    price: 136.65,
    votes: 18505,
    createdDateTime: "2024-10-23T14:05:20.999",
  },
  {
    _id: "FRG100383",
    brandName: "Armani",
    brandAbr: "ARMANI",
    model: "Acqua di Gio",
    img: "https://fimgs.net/mdimg/perfume/375x500.410.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Giorgio-Armani/Acqua-di-Gio-410.html",
    formula: "EDT",
    year: 1996,
    category: "FRESH",
    popularRate: 9.45,
    status: "CLASSIC",
    gender: "MALE",
    rate: 4.39,
    price: 65.95,
    votes: 22405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    _id: "FRG100125",
    brandName: "Paco Rabannne",
    brandAbr: "PACO",
    model: "1 Million",
    img: "https://fimgs.net/mdimg/perfume/375x500.3747.jpg",
    fragUrl: "https://www.fragrantica.com/perfume/Rabanne/1-Million-3747.html",
    formula: "EDT",
    year: 2008,
    category: "WOODY",
    popularRate: 9.35,
    status: "CLASSIC",
    gender: "MALE",
    rate: 4.15,
    price: 77.95,
    votes: 23405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    _id: "FRG100410",
    brandName: "Maison Margiela",
    brandAbr: "MARGIELA",
    model: "By the Fireplace",
    img: "https://fimgs.net/mdimg/perfume/375x500.31623.jpg",
    gender: "UNISEX",
    price: 89.2,
    rate: 4.4,
    status: "CLASSIC",
    year: 2015,
    category: "WOODY",
    formula: "EDT",
    popularRate: 9.6,
  },
  {
    _id: "FRG100178",
    brandName: "Tom Ford",
    brandAbr: "TF",
    model: "Tobacco Vanille",
    img: "https://fimgs.net/mdimg/perfume/375x500.1825.jpg",
    gender: "UNISEX",
    price: 354.35,
    rate: 4.31,
    status: "OG",
    year: 2007,
    category: "ORIENTAL",
    formula: "EDP",
    popularRate: 9.5,
  },
  {
    id: "FRG100109",
    brandName: "Hermes",
    brandAbr: "HERMES",
    model: "Terre d'Hermes",
    img: "https://fimgs.net/mdimg/perfume/375x500.17.jpg",
    gender: "MALE",
    price: 79.99,
    rate: 4.37,
    status: "CLASSIC",
    year: 2006,
    category: "WOODY",
    formula: "EDT",
    popularRate: 9.55,
  },
  {
    id: "FRG100024",
    brandName: "Yves Saint Laurent",
    brandAbr: "YSL",
    model: "La Nuit de l'Homme",
    img: "https://fimgs.net/mdimg/perfume/375x500.5521.jpg",
    gender: "MALE",
    price: 119.99,
    rate: 4.58,
    status: "OG",
    year: 2009,
    category: "WOODY",
    formula: "EDT",
    popularRate: 9.45,
  },
  {
    id: "FRG100383",
    brandName: "Armani",
    brandAbr: "ARMANI",
    model: "Acqua di Gio",
    img: "https://fimgs.net/mdimg/perfume/375x500.410.jpg",
    gender: "MALE",
    price: 64.8,
    rate: 4.35,
    status: "OG",
    year: 1996,
    category: "FRESH",
    formula: "EDT",
    popularRate: 9.45,
  },
  {
    id: "FRG100411",
    brandName: "Maison Margiela",
    brandAbr: "MARGIELA",
    model: "Jazz Club",
    img: "https://fimgs.net/mdimg/perfume/375x500.20541.jpg",
    gender: "MALE",
    price: 99.8,
    rate: 4.37,
    status: "OG",
    year: 2013,
    category: "LEATHER",
    formula: "EDT",
    popularRate: 9.45,
  },
];

import { Icon } from "@iconify/react";
import * as Accordion from "@radix-ui/react-accordion";
import { HTMLAttributes, useEffect, useState } from "react";

interface HelpProps extends HTMLAttributes<HTMLDivElement> {
  type: string;
}

export default function FaqAccordion({ type }: HelpProps) {
  const action = () => {
    switch (type) {
      case "buy":
        return buyData;
        break;
      case "sell":
        return sellData;
        break;
      default:
        return buyData;
    }
  };
  return (
    <section>
      <p className="text-xl font-semibold text-center">
        Frequently Asked Questions
      </p>
      <Accordion.Root
        type="single"
        className="lg:space-y-4 space-y-2 my-6"
        collapsible
      >
        {action().map((_, idx) => (
          <Accordion.Item
            value={idx.toString()}
            role="button"
            className="lg:p-4 p-2 bg-white rounded-lg text-sm text-left border-b last:border-0 border-slate-200"
            key={_.ques}
          >
            <Accordion.Trigger asChild className="group">
              <div className="flex items-center justify-between">
                {_.ques}
                <Icon
                  icon={"iconamoon:arrow-up-2-light"}
                  className="w-8 text-xl transition-all rotate-180 group-aria-expanded:rotate-0"
                />
              </div>
            </Accordion.Trigger>
            <Accordion.Content className="pt-3 mt-3 border-t border-t-black/5">
              {_.ans}
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </section>
  );
}

const buyData = [
  {
    ques: "What is my protection as a buyer?",
    ans: "Every perfume sold by Pairrit 100% Grade A Oil Perfumery. Shop on Pairrit with confidence knowing every purchase is Pairrit Verified. You are free to return within 3 days if you are unsatified.",
  },
  {
    ques: "Free Shipping?",
    ans: "Earn your free shippiing on orders over $79. We ship out within 24 hours. Guaranteed!",
  },
  {
    ques: "How are returns handled?",
    ans: "You have 3 days to request a return from the date you received the item(s), we only accept returns on New Products. We do not issue refunds or accept returns or exchanges on any used(Non-New) items sold.",
  },
  {
    ques: "What are the steps of getting a refund/return started?",
    ans: "Request a refund on the order. Wait 24 Hours. Once approved, ship the item back us. Once the item is verified by us, your refund should be initiated in 48 hours. ",
  },
  {
    ques: "Who is responsible for return shipping costs?",
    ans: "Only damaged products are eligible for returns. Item(s) must include the return label and packinng slip provided by us, we will provide a prepaid label.",
  },
  {
    ques: "How do I cancel an order?",
    ans: "You may cancel your order within 3 hours by heading to your Purchases/Orders tab, locating the specific order, and following the steps. First Contact us via the contact center, email or social media before canceling the order. ",
  },
  {
    ques: "How to contact Pairrit?",
    ans: "Send us a message on the contact page or on our social platforms. We respond to messages within 24 hours.",
  },
  {
    ques: "Shipping Costs?",
    ans: "The cost of shipping is $5 to the 48 contiguous states and $7.25 to Hawaii and Alaska. We offer next day shipping at an additional $4.",
  },
  {
    ques: "Expected shipping time?",
    ans: "It will typically take 3-5 busniess days (M-F) for orders to be delievered to buyer located in the US.",
  },
  {
    ques: "Shipping outside United States?",
    ans: "We only currently ship to United States at the moment, as we grow we will serve other countries. Thank You",
  },
];

const sellData = [
  {
    ques: "Sellers Waitlist?",
    ans: "Pairrit is a fragrance marketplace, where fragrance resellers and retailers are able to resell their fragrance at low fees!",
  },
  {
    ques: "How do I join the sellers waitlist?",
    ans: "To Joing the waitlist, Go to 'Join Sellers List', then fill out the details. First 100 Sellers receive a lifetime half off all fees (4%)",
  },
  {
    ques: "How do I list my item on Pairrit?",
    ans: "Listing is quick and easy. After setting up shop, Go to 'Sell Fragrance', then select the appropriate selections. Fill out the information according to your fragrance description, details and photo's.",
  },
  {
    ques: "What fees will I pay for selling on Pairrit?",
    ans: "There is a 8% commission fee for every item sold on Pairrit. There are no fees for creating a new listing or removing a listing if it has not sold. This fee is assessed off of the total sale amount (including shipping).",
  },
  {
    ques: "I sold an item, what's next?",
    ans: "We will send you a prepaid, pre-addressed shipping label to your email. Drop it off with the courier listed, once the item has shipped, and passes our verification will initiate your payout. ",
  },
];
